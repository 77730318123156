<template>
  <section
    v-if="answers != null"
    class="anamnese-sec"
  >
    <div class="full-container">
      <div class="title">
        <h2 v-if="currentQuestion < 6">Deel 1: Anamnese bewegingsstoornissen</h2>
        <h2 v-if="currentQuestion > 5 && currentQuestion < 17">Deel 2: Onderzoek bewegingsstoornissen</h2>
      </div>

      <div class="anamnese-inner">
        <div class="anamnese-steps anamnese-steps-left">
          <!-- <p class="sub-title-anamnese-left">Deel 1: Anamnese</p> -->

          <ul>
            <li
              @click="currentQuestion = 1"
              :class="getClassNumber(1, 'one')"
            >
              <a href="#">
                <span>1</span>
                <p>{{ answers.one.name }}</p>
              </a>
            </li>
            <li
              @click="currentQuestion = 2"
              :class="getClassNumber(2, 'two')"
            >
              <a href="#">
                <span>2</span>
                <p>{{ answers.two.name }}</p>
              </a>
            </li>
            <li
              @click="currentQuestion = 3"
              :class="getClassNumber(3, 'three')"
            >
              <a href="#">
                <span>3</span>
                <p>{{ answers.three.name }}</p>
              </a>
            </li>
            <li
              @click="currentQuestion = 4"
              :class="getClassNumber(4, 'four')"
            >
              <a href="#">
                <span>4</span>
                <p>{{ answers.four.name }}</p>
              </a>
            </li>
            <li
              @click="currentQuestion = 5"
              :class="getClassNumber(5, 'five')"
            >
              <a href="#">
                <span>5</span>
                <p>{{ answers.five.name }}</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="anamnese-detail">
          <div v-if="currentQuestion == 1"><QuestionOne /></div>
          <div v-if="currentQuestion == 2"><QuestionTwo /></div>
          <div v-if="currentQuestion == 3"><QuestionThree /></div>
          <div v-if="currentQuestion == 4"><QuestionFour /></div>
          <div v-if="currentQuestion == 5"><QuestionFive /></div>
          <div v-if="currentQuestion == 6"><QuestionSix /></div>
          <div v-if="currentQuestion == 7"><QuestionSeven /></div>
          <div v-if="currentQuestion == 8"><QuestionEight /></div>
          <div v-if="currentQuestion == 9"><QuestionNine /></div>
          <div v-if="currentQuestion == 10"><QuestionTen /></div>
          <div v-if="currentQuestion == 11"><QuestionEleven /></div>
          <div v-if="currentQuestion == 12"><QuestionTwelve /></div>
          <div v-if="currentQuestion == 13"><QuestionThirteen /></div>
          <div v-if="currentQuestion == 14"><QuestionFourteen /></div>
          <div v-if="currentQuestion == 15"><QuestionFifteen /></div>
          <div v-if="currentQuestion == 16"><QuestionSixteen /></div>
          <div v-if="currentQuestion == 17"><Check /></div>
          <div v-if="currentQuestion == 18"><Result /></div>

          <div class="button-row">
            <a
              v-if="currentQuestion > 1 && currentQuestion != 17"
              class="next-btn previous-btn"
              :style="currentQuestion == 18 ? 'margin-top: 60px; margin-bottom: 0px;' : ''"
              @click.prevent="openPreviousQuestion()"
            >
              Vorige
            </a>
            <a
              v-if="currentQuestion < 17"
              class="next-btn"
              @click.prevent="openNextQuestion()"
            >
              Volgende
            </a>
          </div>

          <div
            v-if="currentQuestion == 17"
            class="button-row generate-report"
          >
            <a
              class="next-btn previous-btn"
              @click.prevent="openPreviousQuestion()"
            >
              Vorige
            </a>
            <a
              class="next-btn generate-btn"
              :style="isGenerating ? 'padding: 12px 92px 12px 32px !important' : ''"
              @click.prevent="generateRapport()"
              @mouseenter="mouseHoverGeneratingButton = true"
              @mouseleave="mouseHoverGeneratingButton = false"
            >
              Genereer rapport
              <div
                v-if="isGenerating"
                class="lds-ellipsis"
              >
                <div :style="mouseHoverGeneratingButton ? 'background-color: #1e85ff;' : 'background-color: #fff;'"></div>
                <div :style="mouseHoverGeneratingButton ? 'background-color: #1e85ff;' : 'background-color: #fff;'"></div>
                <div :style="mouseHoverGeneratingButton ? 'background-color: #1e85ff;' : 'background-color: #fff;'"></div>
                <div :style="mouseHoverGeneratingButton ? 'background-color: #1e85ff;' : 'background-color: #fff;'"></div>
              </div>
            </a>
          </div>
        </div>
        <div class="anamnese-steps anamnese-steps-right">
          <!-- <p class="sub-title-anamnese-right">Deel 2: Onderzoek</p> -->

          <ul>
            <li
              @click="currentQuestion = 6"
              :class="getClassNumber(6, 'six')"
            >
              <a href="#">
                <p>{{ answers.six.name }}</p>
                <span>1</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 7"
              :class="getClassNumber(7, 'seven')"
            >
              <a href="#">
                <p>{{ answers.seven.name }}</p>
                <span>2</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 8"
              :class="getClassNumber(8, 'eight')"
            >
              <a href="#">
                <p>{{ answers.eight.name }}</p>
                <span>3</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 9"
              :class="getClassNumber(9, 'nine')"
            >
              <a href="#">
                <p>{{ answers.nine.name }}</p>
                <span>4</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 10"
              :class="getClassNumber(10, 'ten')"
            >
              <a href="#">
                <p>{{ answers.ten.name }}</p>
                <span>5</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 11"
              :class="getClassNumber(11, 'eleven')"
            >
              <a href="#">
                <p>{{ answers.eleven.name }}</p>
                <span>6</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 12"
              :class="getClassNumber(12, 'twelve')"
            >
              <a href="#">
                <p>{{ answers.twelve.name }}</p>
                <span>7</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 13"
              :class="getClassNumber(13, 'thirteen')"
            >
              <a href="#">
                <p>{{ answers.thirteen.name }}</p>
                <span>8</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 14"
              :class="getClassNumber(14, 'fourteen')"
            >
              <a href="#">
                <p>{{ answers.fourteen.name }}</p>
                <span>9</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 15"
              :class="getClassNumber(15, 'fifteen')"
            >
              <a href="#">
                <p>{{ answers.fifteen.name }}</p>
                <span>10</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 16"
              :class="getClassNumber(16, 'sixteen')"
            >
              <a href="#">
                <p>{{ answers.sixteen.name }}</p>
                <span>11</span>
              </a>
            </li>
            <li
              @click="currentQuestion = 17"
              :class="getClassNumber(17, 'check')"
            >
              <a href="#">
                <p>Controle</p>
                <span>12</span>
              </a>
            </li>
            <!-- <li :class="getClassNumber(18, 'result')">
              <a class="result-number" href="#">
                <p>Resultaat</p>
                <span>13</span>
              </a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { VueCookieNext } from "vue-cookie-next";

import QuestionOne from "../components/questions/QuestionOne.vue";
import QuestionTwo from "../components/questions/QuestionTwo.vue";
import QuestionThree from "../components/questions/QuestionThree.vue";
import QuestionFour from "../components/questions/QuestionFour.vue";
import QuestionFive from "../components/questions/QuestionFive.vue";
import QuestionSix from "../components/questions/QuestionSix.vue";
import QuestionSeven from "../components/questions/QuestionSeven.vue";
import QuestionEight from "../components/questions/QuestionEight.vue";
import QuestionNine from "../components/questions/QuestionNine.vue";
import QuestionTen from "../components/questions/QuestionTen.vue";
import QuestionEleven from "../components/questions/QuestionEleven.vue";
import QuestionTwelve from "../components/questions/QuestionTwelve.vue";
import QuestionThirteen from "../components/questions/QuestionThirteen.vue";
import QuestionFourteen from "../components/questions/QuestionFourteen.vue";
import QuestionFifteen from "../components/questions/QuestionFifteen.vue";
import QuestionSixteen from "../components/questions/QuestionSixteen.vue";
import Check from "../views/Check.vue";
import Result from "../views/Result.vue";
import useAnswers from "../composables/useAnswers.js";
import CryptoJS from "crypto-js";
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: "Questions",
  components: {
    QuestionOne,
    QuestionTwo,
    QuestionThree,
    QuestionFour,
    QuestionFive,
    QuestionSix,
    QuestionSeven,
    QuestionEight,
    QuestionNine,
    QuestionTen,
    QuestionEleven,
    QuestionTwelve,
    QuestionThirteen,
    QuestionFourteen,
    QuestionFifteen,
    QuestionSixteen,
    Check,
    Result,
  },
  setup() {
    window.scrollTo(0, 0);

    const { answers } = useAnswers();

    const currentQuestion = ref(1);

    const epsCurrentQuestion = VueCookieNext.getCookie("eps-current-question");

    const isGenerating = ref(false);

    const mouseHoverGeneratingButton = ref(false);

    const analytics = getAnalytics();

    if (epsCurrentQuestion) {
      currentQuestion.value = Number(epsCurrentQuestion);
    }

    const encrypt = (text, secret) => {
      return CryptoJS.AES.encrypt(text, secret).toString();
    };

    const openNextQuestion = () => {
      currentQuestion.value++;
      window.scrollTo(0, 0);

      try {
        const uniqueKey = Math.random().toString();
        const parsedAnswersJSON = JSON.stringify(answers.value);
        const parsedAnsersEncrypted = encrypt(parsedAnswersJSON, uniqueKey).toString();
        VueCookieNext.setCookie("eps-key", uniqueKey, {
          expire: "1h",
        });
        VueCookieNext.setCookie("eps-current-question", currentQuestion.value.toString(), {
          expire: "1h",
        });
        localStorage.setItem("eps-value", parsedAnsersEncrypted);
      } catch (error) {
        s;
        console.log(error.message);
      }
    };

    const openPreviousQuestion = () => {
      currentQuestion.value--;
      window.scrollTo(0, 0);

      try {
        const uniqueKey = Math.random().toString();
        const parsedAnswersJSON = JSON.stringify(answers.value);
        const parsedAnsersEncrypted = encrypt(parsedAnswersJSON, uniqueKey).toString();
        VueCookieNext.setCookie("eps-key", uniqueKey, {
          expire: "1h",
        });
        VueCookieNext.setCookie("eps-current-question", currentQuestion.value.toString(), {
          expire: "1h",
        });
        localStorage.setItem("eps-value", parsedAnsersEncrypted);
        // alert(parsedAnswersJSON);
      } catch (error) {
        console.log(error.message);
      }
    };

    const generateRapport = async () => {
      isGenerating.value = true;
      logEvent(analytics, "EPS rapport gegenereerd");

      await sleep(2000);
      openNextQuestion();
      isGenerating.value = false;
    };

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const getClassNumber = (number, questionString) => {
      let classString = "";

      if (number == currentQuestion.value) {
        classString = classString + " active";
      }

      let hasFinished = false;

      if (number < 6) {
        for (const [key, value] of Object.entries(answers.value[questionString])) {
          if (value.answer != "") {
            hasFinished = true;
          } else {
            hasFinished = false;

            break;
          }
        }
      } else if (number < 17) {
        for (const [key, value] of Object.entries(answers.value[questionString])) {
          if (value.selected && key != "name") {
            hasFinished = true;
          }
        }
      } else if (number == 18) {
        classString = "result-number";
      }

      if (hasFinished) {
        classString = classString + " complete";
      }

      return classString;
    };

    return {
      answers,
      openPreviousQuestion,
      openNextQuestion,
      currentQuestion,
      getClassNumber,
      generateRapport,
      isGenerating,
      mouseHoverGeneratingButton,
    };
  },
};
</script>

<style scoped>
.result-number {
  cursor: default;
}
</style>
