import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import useAnswers from "../composables/useAnswers.js";
import { getValue } from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";

// import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDw_mumiYaSrcGHJ4P3fB5Oj73pl1GZszU",
  authDomain: "eps-tool.firebaseapp.com",
  projectId: "eps-tool",
  storageBucket: "eps-tool.appspot.com",
  messagingSenderId: "884920693402",
  appId: "1:884920693402:web:3776daba08f82923592523",
  measurementId: "G-E0TBEE9FZ9",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const projectAuth = firebase.auth();
const remoteConfig = getRemoteConfig();
const { answers } = useAnswers();
const analytics = getAnalytics(app);

// remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.settings.minimumFetchIntervalMillis = 1000;

remoteConfig.defaultConfig = {
  questions_and_answers: "{}",
};

fetchAndActivate(remoteConfig).then(() => {
  const answers_from_firebase = getValue(remoteConfig, "questions_and_answers");
  const converted_answers = JSON.parse(answers_from_firebase["_value"]);
  answers.value = converted_answers;
});

export { projectAuth, remoteConfig };
