<template>
  <div>
    <section class="preface-sec">
      <div class="custom-container">
        <!-- <div class="title">
        <h2>Welkom bij EPS-Tool</h2>
      </div> -->
        <div class="preface-inner">
          <div class="preface-box">
            <div class="text-box">
              <br />
              <h1 style="font-size: 26px">Welkom bij EPS-Tool</h1>
              <br />
              <br />
              <p>
                Via de EPS-tool kan op een eenvoudige en gestandaardiseerde manier een bewegingsstoornissenonderzoek worden afgenomen. Het is een gedigitaliseerde versie van de veel gebruikte methode van prof. dr. Peter N. van Harten. Regelmatig geven patiënten aan last te hebben van bewegingsstoornissen ten gevolge van de medicatie die ze gebruiken. Door gebruik van deze digitale versie is het mogelijk om op een uniforme manier ernstschalen toe te voegen en na afloop van het onderzoek een
                rapport te downloaden.
              </p>
              <br />
              <p>Samen met het bedrijf Thinkaholic en in overleg met prof. dr. Van Harten is daarom deze website ontwikkeld. Het doel is om vaker en op uniforme manier het onderzoek uit te voeren zodat bewegingsstoornissen ten gevolge van medicatie tijdig worden gesignaleerd en gerichte actie volgt.</p>
              <br />
              <p>
                Meer informatie over deze methode, adviezen over hoe om te gaan bewegingsstoornissen en wetenschappelijke publicaties vind je op de website
                <a
                  class="normal_link"
                  href="https://bewegingsstoornissenindepsychiatrie.nl/"
                  target="_blank"
                >
                  www.bewegingsstoornissenindepsychiatrie.nl.
                </a>
              </p>
              <br />
              <p>
                Voer je het onderzoek voor de eerste keer uit of wil je de afzonderlijke handelingen van het onderzoek nog eens doornemen, dan is het bekijken van deze
                <a
                  href="https://www.youtube.com/watch?v=4fWEhDb4wHw&t=149s"
                  target="_blank"
                  class="normal_link"
                >
                  uitlegvideo
                </a>
                de moeite waard.
              </p>
              <br />
              <br />
              <h4>Het onderzoek</h4>
              <p>Het onderzoek is verdeeld in 2 delen:</p>
              <br />
              <p>- Deel 1 anamnese: wat vertelt de patiënt, ook als je het niet terug ziet in het bewegingsonderzoek. Bewegingsstoornissen kunnen wisselend aanwezig zijn en worden beïnvloed door stress.</p>
              <br />
              <p>- Deel 2 bewegingsonderzoek: invullen wat je ziet. Welke bewegingsstoornis, waar, wat is de ernst, wat is de last en de ervaring van de patiënt.</p>
              <br />
              <br />
              <h4>Privacy</h4>

              <p>EPS-tool is AVG-proof en voldoet aan alle privacy voorwaarden. Ingevulde gegevens worden maximaal een uur lokaal opgeslagen in een beveiligde kluis. Na deze periode worden de gegevens automatisch verwijderd. Daarnaast worden geen persoonsgegevens opgeslagen: deze dienen handmatig achteraf te worden ingevuld op het gedownloade onderzoek.</p>
              <br />
              <br />

              <h4>Tip</h4>

              <p>Een foto of video zegt vaak meer dan een beschrijving. Maak bij de start een foto of video in overleg met je patiënt en herhaal deze na een ondernomen actie, bijvoorbeeld na psycho-educatie of aanpassing medicatie.</p>
              <br />
              <p>Succes met invullen!</p>
              <br />
              <p>Rob van der Laan, MSc (GGZ verpleegkundige)</p>

              <p>
                Martijn van der Laan, MSc MA (Eigenaar
                <a
                  class="normal_link"
                  href="https://thinkaholic.nl"
                  target="_blank"
                >
                  Thinkaholic Web & Apps
                </a>
                )
              </p>
              <br />
              <br />
              <br />
              <p>
                <i>
                  Wij blijven de tool constant verbeteren en uitbreiden. Heeft u een bug geconstateerd, een tip of iets anders wat u ons wilt melden? Stuur dan een e-mail naar
                  <a
                    class="normal_link"
                    href="mailto:contact@eps-tool.nl"
                  >
                    contact@eps-tool.nl
                  </a>
                  .
                </i>
              </p>
              <br />
            </div>

            <!-- <div class="image-holder">
            <img src="/images/video-img.png" alt="" class="img-fluid" />
            <div class="text-inner">
              <a href="#"><img src="/images/play-icon.svg" alt=""/></a>
              <p>
                Uitleg EPS-Tool door Prof. dr. Peter N. van Harten
                <span> 4 minuten </span>
              </p>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </section>
    <div class="text-box floating-button">
      <div class="link">
        <router-link to="onderzoek">Start onderzoek</router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Start",
  components: {},
};
</script>

<style scoped>
.normal_link {
  color: #1e85ff;
}

.normal_link:hover {
  color: #c7c7c7;
}

.text-box .link a {
  background-color: #1e85ff;
  border-radius: 30px;
  width: 20%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #1e85ff;
  position: fixed;
  bottom: 5%;
  margin-left: 40%; /* Negative half of width. */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 600px) {
  .text-box .link a {
    width: 80%;
    margin-left: 10%; /* Negative half of width. */
    bottom: 4%;
  }
}
.text-box .link a:hover {
  background-color: #fff;
  color: #1e85ff;
}

* {
  text-align: left;
}

h4 {
  font-size: 22px !important;
}
</style>
