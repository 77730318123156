<template>
  <div class="anamnese-title">
    <h4>Controle</h4>
  </div>
  <div class="text-box">
    <h4>
      Controleer onderstaande gegevens. Als alles correct is ingevuld, kunt u
      klikken op 'Genereer rapport'. Het EPS-rapport wordt vervolgens in PDF en
      DOCX gegenereerd.
    </h4>
  </div>
  <div class="completion-inner">
    <div class="completion-box">
      <div class="text-inner">
        <h5>EPS Onderzoek - Uitslag</h5>
        <!-- <ul>
          <li>Naam: Rob van der La</li>
          <li>Naam cliënt: -</li>
          <li>Naam organisatie: -</li>
          <li>Aanvullende opmerking: -</li>
        </ul> -->
      </div>
      <div class="text-inner">
        <h6>Deel 1: Anamnese bewegingsstoornissen</h6>
        <ul>
          <li v-for="(value, name, index) in answers" :key="index">
            <div v-if="index < 5" class="first-section-check">
              <h4 class="check-subtitle">{{ index + 1 }}. {{ value.name }}</h4>
              <li v-for="answer in value" :key="answer">
                <div v-if="answer.question" class="answer-box-check">
                  <p class="question-check">
                    <i><b>Vraag: </b>{{ striptags(answer.question) }}</i>
                  </p>
                  <p v-if="answer.answer" class="question-check">
                    <b>Antwoord: </b>{{ answer.answer }}
                  </p>
                  <p style="color: red" v-else>
                    <b style="color: #707070">Antwoord: </b>Deze vraag is nog
                    niet beantwoord.
                  </p>
                  <p v-if="answer.explanation" class="question-check">
                    <b>Toelichting: </b>{{ answer.explanation }}
                  </p>
                </div>
              </li>
            </div>
          </li>
        </ul>
        <h6 style="margin-top: 32px;">
          Deel 2: Onderzoek bewegingsstoornissen
        </h6>
        <ul>
          <li v-for="(value, name, index) in answers" :key="index">
            <div v-if="index > 4" class="first-section-check">
              <h4 class="check-subtitle">{{ index - 4 }}. {{ value.name }}</h4>
              <div class="answer-box-check">
                <i
                  ><p><b>Beweging: </b>{{ striptags(value.question) }}</p></i
                >
                <div v-if="questionIsAnswered(value)">
                  <li v-for="answer in value" :key="answer">
                    <div v-if="answer.selected" class="answer-box-check">
                      <p class="question-check"></p>
                      <p><b>Observatie: </b>{{ answer.name }}</p>
                      <p v-if="!answer.explanation && answer.scale">
                        <b>Schaal: </b>{{ answer.scale }}
                      </p>
                      <p v-if="answer.explanation">
                        <b>Uitleg: </b>{{ answer.explanation }}
                      </p>
                    </div>
                  </li>
                  <p
                    v-if="value.explanation != '' && value.explanation != null"
                  >
                    <br />
                    <b>Opmerking: </b>{{ value.explanation }}
                  </p>
                </div>
                <div style="padding-top: 12px !important;" v-else>
                  <p style="color: red">
                    Deze vraag is nog niet beantwoord.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useAnswers from "../composables/useAnswers.js";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import striptags from "striptags";

export default {
  components: {
    VueSlider,
  },
  setup() {
    const { answers } = useAnswers();

    const questionIsAnswered = (question) => {
      for (const [key, value] of Object.entries(question)) {
        if (value.selected) {
          return true;
        }
      }
      return false;
    };

    return {
      answers,
      questionIsAnswered,
      striptags,
    };
  },
};
</script>

<style>
.eps-slider {
  margin-top: 20px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 700px) {
  .eps-slider {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.vue-slider-process {
  background-color: #1e85ff !important;
}

.vue-slider-dot-tooltip-inner {
  border-color: #1e85ff !important;
  background-color: #1e85ff !important;
}

.vue-slider-rail {
  background-color: #c3c3c3 !important;
}

.question-check {
  margin-bottom: 12px !important;
}

.answer-box-check {
  text-align: start;
  padding: 0px 16px 16px 0px;
}

.check-subtitle {
  padding: 32px 0px 24px 0px;
  text-align: start;
}
</style>
