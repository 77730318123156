<template>
  <header>
    <div class="custom-container main-header">
      <div class="navigation">
        <div style="display: flex;align-items: center;">
		
          <router-link to="/" class="logo"><img src="/images/logo.svg" alt="" /></router-link>
        </div>

        <input class="menu-btn" type="checkbox" id="menu-btn" />
        <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
        <ul class="menu">
          <li><router-link :to="{ name: 'privacy' }">Privacy</router-link></li>
          <li><router-link :to="{ name: 'contact' }">Contact</router-link></li>
          <div class="on-mobile"></div>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import Result from "../views/Result.vue";
export default {
  components: { Result },
  name: "HeaderNav",
};
</script>

<style></style>
