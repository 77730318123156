<template>
  <div>
    <div class="anamnese-title">
      <span>1/5</span>
      <h4>{{ answers.one.name }}</h4>
    </div>
    <div class="text-box">
      <div class="container">
        <div class="row">
          <div class="col align-items">
            <h4>A. {{ answers.one.a.question }}</h4>
            <div
              v-if="answers.one.a.tooltip != ''"
              class="tooltip-circle"
              data-tooltip-location="left"
              :data-tooltip="answers.one.a.tooltip"
            >
              i
            </div>
          </div>
        </div>
      </div>

      <p>{{ answers.one.a.explanation }}</p>
    </div>
    <div class="text-box">
      <div class="container">
        <div class="row">
          <div class="col align-items">
            <h4>B. {{ answers.one.b.question }}</h4>
            <div
              v-if="answers.one.b.tooltip != ''"
              class="tooltip-circle"
              data-tooltip-location="left"
              :data-tooltip="answers.one.b.tooltip"
            >
              i
            </div>
          </div>
        </div>
      </div>
      <ul>
        <li
          :key="answers.one.b.answer"
          :class="answers.one.b.answer == 'Ja' ? 'active' : ''"
        >
          <button
            type="button"
            @click.prevent="onClickBoolean('one', 'b', 'Ja')"
          >
            <span>
              <img
                src="images/check-icon.svg"
                alt=""
              />
            </span>
            Ja
          </button>
        </li>
        <li :class="answers.one.b.answer == 'Nee' ? 'active' : ''">
          <button @click.prevent="onClickBoolean('one', 'b', 'Nee')">
            <span>
              <img
                src="images/check-icon.svg"
                alt=""
              />
            </span>
            Nee
          </button>
        </li>
      </ul>
      <textarea
        placeholder="Toelichting"
        class="form-control"
        v-model="answers.one.b.explanation"
      ></textarea>
    </div>
    <div class="text-box">
      <div class="container">
        <div class="row">
          <div class="col align-items">
            <h4>C. {{ answers.one.c.question }}</h4>
            <div
              v-if="answers.one.c.tooltip != ''"
              class="tooltip-circle"
              data-tooltip-location="left"
              :data-tooltip="answers.one.c.tooltip"
            >
              i
            </div>
          </div>
        </div>
      </div>

      <textarea
        placeholder="Antwoord"
        class="form-control"
        v-model="answers.one.c.answer"
      ></textarea>
    </div>
    <div class="text-box">
      <div class="container">
        <div class="row">
          <div class="col align-items">
            <h4>D. {{ answers.one.d.question }}</h4>
            <div
              v-if="answers.one.d.tooltip != ''"
              class="tooltip-circle"
              data-tooltip-location="left"
              :data-tooltip="answers.one.d.tooltip"
            >
              i
            </div>
          </div>
        </div>
      </div>

      <textarea
        placeholder="Antwoord"
        class="form-control"
        v-model="answers.one.d.answer"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
// @ is an alias to /src

import useAnswers from "../../composables/useAnswers.js";
import { watchEffect } from "@vue/runtime-core";

export default {
  components: {},
  setup() {
    const { answers } = useAnswers();

    const onClickBoolean = (question, letter, answer) => {
      if (answers.value[question][letter].answer == answer) {
        answers.value[question][letter].answer = "";
      } else {
        answers.value[question][letter].answer = answer;
      }
    };

    return { answers, onClickBoolean };
  },
};
</script>

<style scoped>
@media (min-width: 991px) {
  .anamnese-detail .text-box ul li {
    display: inline-block;
    margin: 0px 8px 8px 8px;
  }
  .anamnese-detail .text-box ul li a {
    min-width: 225px;
  }
}
@media (min-width: 1442px) {
  .anamnese-detail .text-box ul li {
    display: inline-block;
    margin: 0px 8px 8px 8px;
  }
  .anamnese-detail .text-box ul li a {
    min-width: 300px;
  }
}
</style>
