<template>
  <section class="preface-sec">
    <div class="custom-container">
      <!-- <div class="title">
        <h2>Welkom bij EPS-Tool</h2>
      </div> -->
      <div class="preface-inner">
        <div class="preface-box">
          <div class="text-box">
            <h4>Privacy</h4>

            <p>
              1) Het waarborgen van de privacy van bezoekers van www.eps-tool.nl
              is een belangrijke taak voor ons. Ons doel is het verbeteren van
              bewegingsonderzoek binnen de psychiatrie. Wij zijn er niet bij
              gebaat uw gegevens op te slaan en doen dit ook absoluut niet.
            </p>

            <p>
              2) Bij de bouw van EPS-Tool is veel tijd besteed aan privacy,
              aangezien wordt gewerkt met gevoeilige informatie. Ingevulde data
              blijft maximaal een uur op uw computer staan, alvorens deze op een
              professionele manier wordt verwijderd. De opgeslagen data is
              versleuteld (AES - Advanced Encryption Standard) en kan onmogelijk
              worden gekraakt. De sleutel voor deze data blijft maximaal een uur
              op uw computer staan en wordt daarna verwijderd. Als u een nieuw
              onderzoek start, wordt het vorige onderzoek automatisch
              verwijderd.
            </p>

            <p>
              3) www.eps-tool.nl plaatst cookies bij bezoekers. Dat doen we om
              informatie te verzamelen over de pagina’s die gebruikers op onze
              website bezoeken. Deze cookies zijn volledig anoniem. Daarnaast
              wordt ook een cookies geplaatst om de bij stap 2 genoemde sleutel
              tijdelijk op te slaan.
            </p>
            <p>
              4) U kunt ervoor kiezen om cookies uit te schakelen. Dat doet u
              door gebruik te maken de mogelijkheden van uw browser. U vindt
              meer informatie over deze mogelijkheden op de website van de
              aanbieder van uw browser. Als u dit doet, kunnen lopende
              onderzoeken niet meer op uw computer worden opgeslagen. Zodra u de
              pagina refresht, wordt automatisch een nieuw onderzoek gestart.
            </p>
            <p>
              5) Als u meer informatie wilt ontvangen, of vragen hebt over de
              privacy policy van EPS-Tool en specifiek www.eps-tool.nl, kun u
              ons benaderen via e-mail. Ons e-mailadres is contact@eps-tool.nl.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Privacy",
  components: {},
};
</script>

<style scoped>
p {
  text-align: start;
  margin-bottom: 26px !important;
}

h4 {
  margin-bottom: 40px !important;
}

.normal_link {
  color: #1e85ff;
}

.normal_link:hover {
  color: #c7c7c7;
}
</style>
