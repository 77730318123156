import { createRouter, createWebHistory } from "vue-router";
import Start from "../views/Start.vue";
import Questions from "../views/Questions.vue";
import Privacy from "../views/Privacy.vue";
import Contact from "../views/Contact.vue";

const routes = [
  {
    path: "/",
    name: "start",
    component: Start,
  },
  {
    path: "/onderzoek",
    name: "questions",
    component: Questions,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
