<template>
  <div>
    <div class="anamnese-title">
      <span>18/18</span>
      <h4>Resultaat</h4>
    </div>
    <div class="success-sec">
      <div class="image-holder">
        <img
          src="images/thanks-icon.svg"
          alt=""
        />
      </div>
      <p>Het EPS rapport is gegenereerd. U kunt het hieronder downloaden.</p>
      <ul>
        <li>
          <a
            href="#"
            @click.prevent="generateRapportPDF(true)"
          >
            <img
              src="images/pdf-icon.svg"
              alt=""
            />
            <span>
              Download
              <br />
              PDF
            </span>
          </a>
        </li>
        <li class="word">
          <a
            class="word"
            href="#"
            @click.prevent="generateRapportDOCX()"
          >
            <img
              src="images/microsoftword-icon.svg"
              alt=""
            />
            <span>Download DOCX</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useAnswers from "../composables/useAnswers.js";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import striptags from "striptags";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";

import { TabStopType, Header, TextRun, AlignmentType, convertInchesToTwip, Document, Footer, HeadingLevel, ImageRun, Packer, Paragraph, Table, TableCell, TableRow, VerticalPositionAlign, HorizontalPositionAlign, SectionType, FrameAnchorType, PageNumber, NumberFormat, HeightRule, WidthType } from "docx";

export default {
  components: {
    VueSlider,
  },
  setup() {
    const { answers } = useAnswers();
    const report = ref(null);

    const generateRapportDOCX = async () => {
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      let time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2);
      let headerText = "Gegenereerd op " + date + " om " + time + " via eps-tool.nl";
      let fileName = "eps_" + date + "_" + time.replace(":", "-");

      const getPart1DocX = () => {
        let children = [];

        children.push(
          new Paragraph({
            text: "Deel 1: Anamnese bewegingsstoornissen",
            heading: HeadingLevel.HEADING_2,
            spacing: {
              after: 500,
            },
          })
        );

        Object.entries(answers.value).forEach(([key, value], index) => {
          let currentIndex = index + 1;
          if (currentIndex < 6) {
            children.push(
              new Paragraph({
                text: currentIndex.toString() + ". " + value.name,
                heading: HeadingLevel.HEADING_3,
                spacing: {
                  before: 400,
                  after: 300,
                },
              })
            );

            Object.entries(value).forEach(([key, value], index) => {
              if (value.question != null) {
                children.push(
                  new Paragraph({
                    text: "Vraag: " + value.question,
                    style: "defaultText",
                    spacing: {
                      before: 350,
                      after: 100,
                    },
                  })
                );

                if (value.answer != "") {
                  children.push(
                    new Paragraph({
                      text: "Antwoord: " + value.answer,
                      style: "defaultText",
                      spacing: {
                        after: 100,
                      },
                    })
                  );
                } else {
                  children.push(
                    new Paragraph({
                      text: "Antwoord: Deze vraag is niet beantwoord",
                      style: "errorText",
                      spacing: {
                        after: 100,
                      },
                    })
                  );
                }

                if (value.explanation != "") {
                  children.push(
                    new Paragraph({
                      text: "Toelichting: " + value.explanation,
                      style: "defaultText",
                      spacing: {
                        after: 100,
                      },
                    })
                  );
                }
              }
            });
          }
        });

        return children;
      };

      const getPart2DocX = () => {
        let children = [];

        children.push(
          new Paragraph({
            text: "Deel 2: Onderzoek bewegingsstoornissen",
            heading: HeadingLevel.HEADING_2,
            spacing: {
              after: 500,
              before: 500,
            },
          })
        );

        Object.entries(answers.value).forEach(([key, value], index) => {
          let currentIndex = index + 1;
          if (currentIndex > 5) {
            children.push(
              new Paragraph({
                text: currentIndex.toString() + ". " + value.name,
                heading: HeadingLevel.HEADING_3,
                spacing: {
                  before: 400,
                  after: 300,
                },
              })
            );

            children.push(
              new Paragraph({
                text: "Vraag: " + striptags(value.question),
                style: "defaultText",
                spacing: {
                  before: 350,
                  after: 100,
                },
              })
            );

            let boolHasAnswers = false;

            Object.entries(value).forEach(([key, value], index) => {
              if (key != "name" && key != "question" && key != "tooltip" && key != "explanation") {
                if (value.selected == true && value.name != "Geen van bovenstaande") {
                  boolHasAnswers = true;

                  children.push(
                    new Paragraph({
                      text: "Observatie: " + value.name,
                      style: "defaultText",
                      spacing: {
                        before: 250,
                        after: 100,
                      },
                    })
                  );

                  children.push(
                    new Paragraph({
                      text: "Schaal: " + value.scale,
                      style: "defaultText",
                      spacing: {
                        before: 100,
                        after: 100,
                      },
                    })
                  );
                }

                if (value.name == "Geen van bovenstaande") {
                  if (value.selected == true) {
                    boolHasAnswers = true;

                    children.push(
                      new Paragraph({
                        text: "Observatie: Geen bijzonderheden geconstateerd",
                        style: "defaultText",
                        spacing: {
                          before: 250,
                          after: 100,
                        },
                      })
                    );
                  }
                }
              }
            });

            if (value.explanation != "" && value.explanation != null) {
              children.push(
                new Paragraph({
                  text: "Opmerking: " + value.explanation,
                  style: "defaultText",
                  spacing: {
                    before: 250,
                    after: 100,
                  },
                })
              );
            }

            if (!boolHasAnswers) {
              children.push(
                new Paragraph({
                  text: "Deze vraag is niet beantwoord.",
                  style: "errorText",
                  spacing: {
                    before: 350,
                    after: 100,
                  },
                })
              );
            }
          }
        });

        return children;
      };

      const doc = new Document({
        creator: "EPS-Tool.nl",
        description: headerText,
        title: fileName,
        styles: {
          default: {
            heading1: {
              run: {
                font: "Helvetica",
                size: 40,
                bold: true,
              },
            },
            heading2: {
              run: {
                font: "Helvetica",
                size: 26,
                bold: true,
              },
            },
            heading3: {
              run: {
                font: "Helvetica",
                size: 22,
                bold: true,
              },
            },
            heading4: {
              run: {
                font: "Helvetica",
                size: 26,
                bold: true,
              },
            },
          },
          paragraphStyles: [
            {
              id: "defaultText",
              name: "Default Text",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
                bold: false,
              },
            },
            {
              id: "errorText",
              name: "Error Text",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
                color: "DC143C",
                bold: false,
              },
            },
            {
              id: "headerText",
              name: "Header Text",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 16,
                bold: false,
              },
            },
          ],
        },
        sections: [
          {
            properties: {
              type: SectionType.CONTINUOUS,
              page: {
                pageNumbers: {
                  start: 1,
                  formatType: NumberFormat.DECIMAL,
                },
              },
            },
            headers: {
              default: new Header({
                children: [
                  new Paragraph({
                    text: headerText,
                    style: "headerText",
                    alignment: AlignmentType.RIGHT,
                    size: 16,
                  }),
                ],
              }),
            },
            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    style: "headerText",
                    alignment: AlignmentType.RIGHT,
                    size: 16,
                    children: [
                      new TextRun({
                        children: [PageNumber.CURRENT],
                      }),
                    ],
                  }),
                ],
              }),
            },
            children: [
              new Paragraph({
                text: "EPS Onderzoek - Uitslag",
                heading: HeadingLevel.HEADING_1,
                spacing: {
                  after: 700,
                },
              }),

              new Paragraph({
                text: "Naam organisatie:",
                style: "defaultText",
              }),
              new Paragraph({
                text: "Naam onderzoeker:",
                style: "defaultText",
              }),
              new Paragraph({
                text: "Datum onderzoek:",
                style: "defaultText",
                spacing: {
                  after: 400,
                },
              }),
              new Paragraph({
                text: "Naam patiënt:",
                style: "defaultText",
              }),
              new Paragraph({
                text: "Geboortedatum patiënt:",
                style: "defaultText",
              }),
              new Paragraph({
                text: "Patiënt nummer:",
                style: "defaultText",
                spacing: {
                  after: 400,
                },
              }),
              new Paragraph({
                text: "Huidige medicatie:",
                style: "defaultText",
                spacing: {
                  after: 700,
                },
              }),
            ],
          },
          {
            properties: {
              type: SectionType.CONTINUOUS,
            },
            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    style: "headerText",
                    alignment: AlignmentType.RIGHT,
                    size: 16,
                    children: [
                      new TextRun({
                        children: [PageNumber.CURRENT],
                      }),
                    ],
                  }),
                ],
              }),
            },

            children: getPart1DocX(),
          },
          {
            properties: {
              type: SectionType.CONTINUOUS,
            },
            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    style: "headerText",
                    alignment: AlignmentType.RIGHT,
                    size: 16,
                    children: [
                      new TextRun({
                        children: [PageNumber.CURRENT],
                      }),
                    ],
                  }),
                ],
              }),
            },

            children: getPart2DocX(),
          },
          {
            properties: {
              type: SectionType.CONTINUOUS,
            },
            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    style: "headerText",
                    alignment: AlignmentType.RIGHT,
                    size: 16,
                    children: [
                      new TextRun({
                        children: [PageNumber.CURRENT],
                      }),
                    ],
                  }),
                ],
              }),
            },

            children: [
              new Paragraph({
                text: "",
                style: "defaultText",
                spacing: {
                  after: 800,
                },
              }),
              new Table({
                width: {
                  size: 9000,
                  type: WidthType.DXA,
                },
                margins: {
                  top: 100,
                  right: 50,
                  bottom: 50,
                  left: 200,
                },
                rows: [
                  new TableRow({
                    // height: { height: 1000, rule: HeightRule.AUTO },
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: "Conclusie onderzoeker",
                            style: "defaultText",
                            spacing: {
                              before: 150,
                              after: 200,
                            },
                          }),
                          new Paragraph({
                            text: "",
                            style: "defaultText",
                            spacing: {
                              after: 200,
                            },
                          }),
                          new Paragraph({
                            text: "",
                            style: "defaultText",
                            spacing: {
                              after: 200,
                            },
                          }),
                          new Paragraph({
                            text: "",
                            style: "defaultText",
                            spacing: {
                              after: 200,
                            },
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                text: "",
                style: "defaultText",
                spacing: {
                  after: 600,
                },
              }),
              new Table({
                width: {
                  size: 9000,
                  type: WidthType.DXA,
                },

                margins: {
                  top: 100,
                  right: 50,
                  bottom: 50,
                  left: 200,
                },
                rows: [
                  new TableRow({
                    // height: { height: 1000, rule: HeightRule.AUTO },

                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: "Advies onderzoeker",
                            style: "defaultText",
                            spacing: {
                              before: 150,
                              after: 200,
                            },
                          }),
                          new Paragraph({
                            text: "",
                            style: "defaultText",
                            spacing: {
                              after: 200,
                            },
                          }),
                          new Paragraph({
                            text: "",
                            style: "defaultText",
                            spacing: {
                              after: 200,
                            },
                          }),

                          new Paragraph({
                            text: "",
                            style: "defaultText",
                            spacing: {
                              after: 200,
                            },
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          },
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, fileName + ".docx");
        console.log("Document created successfully");
      });
    };

    const generateRapportPDF = async (boolDownload) => {
      // create pdf bit by bit with jspdf

      let options = {
        maxWidth: 160,
        lineHeightFactor: 1.25,
      };

      let left = 20;
      let right = 200;
      let top = 25;
      let bottom = 280;
      let defaultSpaceBetweenText = 6;
      let mediumSpaceBetweenText = 12;
      let largeSpaceBetweenText = 18;
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      let time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2);
      let headerText = "Gegenereerd op " + date + " om " + time + " via eps-tool.nl";

      var doc = new jsPDF();

      const checkIfEndDocument = (input) => {
        let totalHeight = 0;
        if (typeof input == "string") {
          let listString = doc.splitTextToSize(input, options.maxWidth);
          totalHeight = top + defaultSpaceBetweenText * listString.length;
        } else if (typeof input == "number") {
          totalHeight = top + input;
        }

        if (totalHeight > bottom) {
          doc.addPage();
          top = 25;
        }
      };

      try {
        // first static part of pdf
        doc.setFontSize(22);
        doc.text("EPS Onderzoek - Uitslag", left, top, options);
        doc.setFontSize(8);
        doc.text(headerText, right, top - 15, null, null, "right");

        doc.setFontSize(11);
        top += largeSpaceBetweenText;
        doc.text("Naam organisatie:", left, top, options);
        top += defaultSpaceBetweenText;
        doc.text("Naam onderzoeker:", left, top, options);
        top += defaultSpaceBetweenText;
        doc.text("Datum onderzoek:", left, top, options);
        top += largeSpaceBetweenText;
        doc.text("Naam patiënt:", left, top, options);
        top += defaultSpaceBetweenText;
        doc.text("Geboortedatum patiënt:", left, top, options);
        top += defaultSpaceBetweenText;
        doc.text("Patiënt nummer:", left, top, options);
        top += largeSpaceBetweenText;
        doc.text("Huidige medicatie:", left, top, options);
        top += largeSpaceBetweenText;

        // first part: anamnese
        top += largeSpaceBetweenText;
        doc.setFontSize(18);
        doc.text("Deel 1: Anamnese bewegingsstoornissen", left, top, options);
        top += largeSpaceBetweenText;

        Object.entries(answers.value).forEach(([key, value], index) => {
          let currentIndex = index + 1;
          if (currentIndex < 6) {
            doc.setFontSize(14);
            checkIfEndDocument(currentIndex.toString() + ". " + value.name);

            doc.text(currentIndex.toString() + ". " + value.name, left, top, options);

            top += defaultSpaceBetweenText;

            Object.entries(value).forEach(([key, value], index) => {
              if (value.question != null) {
                doc.setFontSize(11);

                // print question
                checkIfEndDocument("Vraag: " + value.question);
                doc.text("Vraag: " + value.question, left, top, options);

                let arrayOfStringToPrint = doc.splitTextToSize("Vraag: " + value.question, options.maxWidth);

                top += defaultSpaceBetweenText * arrayOfStringToPrint.length;

                // print answer

                if (value.answer != "") {
                  checkIfEndDocument("Antwoord: " + value.answer);

                  doc.text("Antwoord: " + value.answer, left, top, options);

                  arrayOfStringToPrint = doc.splitTextToSize("Antwoord: " + value.answer, options.maxWidth);

                  top += defaultSpaceBetweenText * arrayOfStringToPrint.length;
                } else {
                  let noAnswerGiven = "Deze vraag is niet beantwoord.";
                  checkIfEndDocument("Antwoord: " + noAnswerGiven);

                  doc.setTextColor("red");
                  doc.text("Antwoord: " + noAnswerGiven, left, top, options);
                  doc.setTextColor("black");

                  arrayOfStringToPrint = doc.splitTextToSize("Antwoord: " + noAnswerGiven, options.maxWidth);

                  top += defaultSpaceBetweenText * arrayOfStringToPrint.length;
                }

                if (value.explanation != "") {
                  // print toeliching
                  checkIfEndDocument("Toelichting: " + value.explanation);

                  doc.text("Toelichting: " + value.explanation, left, top, options);

                  arrayOfStringToPrint = doc.splitTextToSize("Toelichting: " + value.explanation, options.maxWidth);

                  top += defaultSpaceBetweenText * arrayOfStringToPrint.length;
                }
              }
              top += defaultSpaceBetweenText;
            });

            top += mediumSpaceBetweenText;
          }
        });

        // second part: onderzoek
        top += mediumSpaceBetweenText;
        doc.setFontSize(18);
        doc.text("Deel 2: Onderzoek bewegingsstoornissen", left, top, options);
        top += largeSpaceBetweenText;

        Object.entries(answers.value).forEach(([key, value], index) => {
          let currentIndex = index + 1;
          if (currentIndex > 5) {
            doc.setFontSize(14);
            checkIfEndDocument(currentIndex.toString() + ". " + value.name);

            doc.text(currentIndex.toString() + ". " + value.name, left, top, options);

            top += mediumSpaceBetweenText - 2;

            doc.setFontSize(11);

            let question = striptags(value.question);
            checkIfEndDocument(question);

            doc.text(question, left, top, options);

            let arrayOfStringToPrint = doc.splitTextToSize(question, options.maxWidth);

            top += 5 * arrayOfStringToPrint.length;

            let boolHasAnswers = false;
            let counter = 0;

            Object.entries(value).forEach(([key, value], index) => {
              if (key != "name" && key != "question" && key != "tooltip" && key != "explanation") {
                if (value.selected == true && value.name != "Geen van bovenstaande") {
                  boolHasAnswers = true;
                  if (counter > 0) {
                    top += mediumSpaceBetweenText;
                  } else {
                    top += defaultSpaceBetweenText;
                  }
                  counter++;

                  checkIfEndDocument("Observatie: " + value.name);
                  doc.text("Observatie: " + value.name, left, top, options);

                  top += defaultSpaceBetweenText;
                  checkIfEndDocument("Schaal: " + value.scale);
                  doc.text("Schaal: " + value.scale, left, top, options);
                }

                if (value.name == "Geen van bovenstaande") {
                  if (value.selected == true) {
                    boolHasAnswers = true;

                    top += defaultSpaceBetweenText;
                    checkIfEndDocument("Observatie: Geen bijzonderheden geconstateerd");
                    doc.text("Observatie: Geen bijzonderheden geconstateerd", left, top, options);
                  }
                }
              }
            });

            if (value.explanation != "" && value.explanation != null) {
              top += mediumSpaceBetweenText;
              checkIfEndDocument("Opmerking: " + value.explanation);
              doc.text("Opmerking: " + value.explanation, left, top, options);
              top += mediumSpaceBetweenText;
            }

            if (!boolHasAnswers) {
              top += defaultSpaceBetweenText;
              let noAnswerGiven = "Deze vraag is niet beantwoord.";
              checkIfEndDocument(noAnswerGiven);

              doc.setTextColor("red");
              doc.text(noAnswerGiven, left, top, options);
              doc.setTextColor("black");

              arrayOfStringToPrint = doc.splitTextToSize(noAnswerGiven, options.maxWidth);

              top += defaultSpaceBetweenText * arrayOfStringToPrint.length;
            }
            top += mediumSpaceBetweenText;
          }
        });

        checkIfEndDocument(50);
        doc.rect(left, top, 165, 50);
        doc.text("Conclusie onderzoeker", left + 4, top + 8, options);
        top += defaultSpaceBetweenText + 55;

        checkIfEndDocument(defaultSpaceBetweenText + 55);

        doc.rect(left, top, 165, 50);
        doc.text("Advies onderzoeker", left + 4, top + 8, options);

        // add page number
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setFontSize(8);

          doc.text("Pagina " + String(i) + " van " + String(pageCount), right, bottom + 5, null, null, "right");
        }

        let fileName = "eps_" + date + "_" + time.replace(":", "-") + ".pdf";

        if (boolDownload) {
          doc.save(fileName);
        } else {
          return doc.output();
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    const questionIsAnswered = (question) => {
      for (const [key, value] of Object.entries(question)) {
        if (value.selected) {
          return true;
        }
      }
      return false;
    };

    return {
      answers,
      generateRapportPDF,
      generateRapportDOCX,
      report,
      striptags,
      questionIsAnswered,
    };
  },
};
</script>

<style>
.eps-slider {
  margin-top: 20px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 700px) {
  .eps-slider {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.vue-slider-process {
  background-color: #1e85ff !important;
}

.vue-slider-dot-tooltip-inner {
  border-color: #1e85ff !important;
  background-color: #1e85ff !important;
}

.vue-slider-rail {
  background-color: #c3c3c3 !important;
}

/* .word {
  opacity: 0.5;
  cursor: not-allowed !important;
} */
</style>
