import { ref } from "vue";

export function useIsIOS() {
  const isIOS = ref(false);

  function checkIsIOS() {
    isIOS.value = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  return { isIOS, checkIsIOS };
}
