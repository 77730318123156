<template>
  <div v-if="isIOS">
    <br />
    <p class="error-text">Let op: op uw iPhone of iPad kan het nodig zijn om knoppen twee keer te selecteren of na het klikken naast de knop te klikken.</p>
  </div>
</template>

<script>
import { useIsIOS } from "../composables/useIsIOS";

export default {
  setup() {
    const { isIOS, checkIsIOS } = useIsIOS();

    checkIsIOS();

    return { isIOS };
  },
};
</script>
<style></style>
