<template>
  <footer>
    <div class="custom-container">
      <div class="footer-inner">
        <p>
          Copyright © {{ new Date().getFullYear() }} -
          <a href="https://thinkaholic.nl" target="_blank" style="color: #000">Thinkaholic Web & Apps</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
