<template>
  <section class="preface-sec">
    <div class="custom-container">
      <!-- <div class="title">
        <h2>Welkom bij EPS-Tool</h2>
      </div> -->
      <div class="preface-inner">
        <div class="preface-box">
          <div class="text-box">
            <h4>Contact</h4>

            <p>
              EPS-Tool is ontwikkeld door Thinkaholic in samenwerking met Rob
              van der Laan en Prof. dr. Peter N. van Harten.
            </p>
            <p>
              Wij blijven de tool constant verbeteren en uitbreiden. Heeft u een
              bug geconstateerd, een tip of iets anders wat u ons wilt melden?
              Stuur dan een e-mail naar
              <a href="mailto:contact@eps-tool.nl">contact@eps-tool.nl</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Contact",
  components: {},
};
</script>

<style scoped>
p {
  text-align: start;
  margin-bottom: 26px !important;
}

h4 {
  margin-bottom: 40px !important;
}

.normal_link {
  color: #1e85ff;
}

.normal_link:hover {
  color: #c7c7c7;
}
</style>
