<template>
  <div class="wrapper">
    <HeaderNav />
    <router-view />
    <Footer />
  </div>
  <Modal v-model="isShow" :close="closeModal">
    <div class="modal modal-window">
      <div class="modal-window-inner">
        <a @click="closeModal" title="Close" class="modal-close"></a>
        <h4>Er staat nog een onderzoek open</h4>
        <p>
          Openstaande onderzoeken worden maximaal een uur bewaard in een
          beveiligde kluis in uw browser. Als u niets doet, wordt het onderzoek
          automatisch verwijderd van uw computer.
        </p>
        <ul>
          <li>
            <a class="star-btn" @click="startNew">Start een nieuw onderzoek</a>
          </li>
          <li>
            <a @click="startWithCurrent">Ga door met de huidige onderzoek</a>
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import HeaderNav from "./components/HeaderNav.vue";
import Footer from "./components/Footer.vue";
import { ref } from "@vue/reactivity";
import { VueCookieNext } from "vue-cookie-next";
import CryptoJS from "crypto-js";
import useAnswers from "./composables/useAnswers.js";
import { useRouter } from "vue-router";

export default {
  components: {
    HeaderNav,
    Footer,
  },
  setup(props, context) {
    const isShow = ref(false);
    const { answers } = useAnswers();
    const router = useRouter();

    function showModal() {
      isShow.value = true;
    }

    function closeModal() {
      isShow.value = false;
    }

    const decrypt = (data, secret) => {
      const decrypt = CryptoJS.AES.decrypt(data, secret);
      const decryptedData = CryptoJS.enc.Utf8.stringify(decrypt);
      return decryptedData;
    };

    const epsKeyFromCookie = VueCookieNext.getCookie("eps-key");
    const epsValueFromLocalStorage = localStorage.getItem("eps-value");

    if (epsKeyFromCookie && epsValueFromLocalStorage) {
      isShow.value = true;
    } else {
      VueCookieNext.removeCookie("eps-key");
      VueCookieNext.removeCookie("eps-current-question");
      localStorage.removeItem("eps-value");
    }

    const startNew = () => {
      VueCookieNext.removeCookie("eps-key");
      VueCookieNext.removeCookie("eps-current-question");
      localStorage.removeItem("eps-value");

      closeModal();

      router.push({
        name: "questions",
      });
    };

    const startWithCurrent = () => {
      const stringValueDecrypted = decrypt(
        epsValueFromLocalStorage,
        epsKeyFromCookie
      );
      const jsonValue = JSON.parse(stringValueDecrypted);
      answers.value = jsonValue;

      router.push({
        name: "questions",
      });

      closeModal();
    };

    return {
      isShow,
      showModal,
      closeModal,
      startNew,
      startWithCurrent,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
