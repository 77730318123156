import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { VueCookieNext } from "vue-cookie-next";
import VueUniversalModal from "vue-universal-modal";
import { projectAuth } from "./firebase/config";

import "./assets/css/swiper-bundle.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "vue-universal-modal/dist/index.css";

let app;

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .use(VueCookieNext)
      .use(VueUniversalModal, {
        teleportTarget: "#modals",
      })
      .mount("#app");
  }
});
