<template>
  <div>
    <div class="anamnese-title">
      <span>4/11</span>
      <h4>{{ answers.nine.name }}</h4>
      <IOSWarning />
    </div>

    <div class="text-box anamnese-detail-research">
      <div class="container">
        <div class="row">
          <div class="col align-items">
            <h4 v-html="answers.nine.question"></h4>
            <div
              v-if="answers.nine.tooltip != ''"
              class="tooltip-circle"
              data-tooltip-location="left"
              :data-tooltip="answers.nine.tooltip"
            >
              i
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-box">
      <ul>
        <li
          v-for="answer in answers.nine"
          :key="answer"
          :class="answer.selected ? 'active' : ''"
        >
          <div v-if="answer.name">
            <div v-if="answer.scale">
              <button
                @click.prevent="
                  answer.selected = !answer.selected;
                  answers.nine.b.selected = false;
                "
              >
                <span>
                  <img
                    src="images/check-icon.svg"
                    alt=""
                  />
                </span>
                {{ answer.name }}
              </button>
              <div
                v-if="answer.selected"
                class="eps-slider"
              >
                <vue-slider
                  v-model="answer.scale"
                  :v-data="['Geen', 'Minimaal', 'Licht', 'Middelmatig', 'Zwaar']"
                  :height="2"
                  :adsorb="true"
                  :marks="true"
                  :dotSize="16"
                ></vue-slider>
              </div>
            </div>
            <div v-else>
              <button
                @click.prevent="
                  answer.selected = !answer.selected;
                  removeAllOptions('nine', 'b');
                "
              >
                <span>
                  <img
                    src="images/check-icon.svg"
                    alt=""
                  />
                </span>
                {{ answer.name }}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <textarea
      placeholder="Toelichting"
      class="form-control toelichting-research"
      v-model="answers.nine.explanation"
    ></textarea>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useAnswers from "../../composables/useAnswers.js";
import VueSlider from "vue-slider-component";
import IOSWarning from "../IOSWarning.vue";

export default {
  components: {
    VueSlider,
    IOSWarning,
  },
  setup() {
    const { answers } = useAnswers();

    const onClickBoolean = (question, letter, answer) => {
      if (answers.value[question][letter].answer == answer) {
        answers.value[question][letter].answer = "";
      } else {
        answers.value[question][letter].answer = answer;
      }
    };

    const removeAllOptions = (questionString, letter) => {
      for (const [key, value] of Object.entries(answers.value[questionString])) {
        if (key != letter && key != "name" && key != "question" && key != "explanation" && key != "tooltip") {
          value.selected = false;
        }
      }
    };

    return { answers, onClickBoolean, removeAllOptions };
  },
};
</script>

<style>
.eps-slider {
  margin-top: 20px;
  margin-bottom: 60px;
}

@media only screen and (min-width: 700px) {
  .eps-slider {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.vue-slider-process {
  background-color: #1e85ff !important;
}

.vue-slider-dot-tooltip-inner {
  border-color: #1e85ff !important;
  background-color: #1e85ff !important;
}

.vue-slider-rail {
  background-color: #c3c3c3 !important;
}
</style>
